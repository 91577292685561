import React, { useState, useEffect } from "react";
import CurrentDateTime from "../../../utils/CurrentDateTime";
import { BsFilter } from "react-icons/bs";
import { ButtonIcon } from "../../../components/Button";
import { GrPowerReset } from "react-icons/gr";
import { ByDateCategory } from "../../../utils/getArrayOfObjectCategory";
import { useSelector, useDispatch } from "react-redux";
import NoPropertyFound from "../../../components/NoPropertyFound";
import { getBuyRequest } from "../ReduxStore/actions";
import { checkCurrentTimeWithTargetTime } from "../../../utils/checkCurrentTimeWithTargetTime";
import {
  searchProperty,
  searchTimeWithinProperty,
} from "../../../utils/searchProperty";
import PuffLoader from "react-spinners/PuffLoader";
import BuyInspectionSearchComponent from "../component/BuyInspectionSearchComponent";
import BuyInspectionPagination from "../../../components/BuyInspectionPagination";

let itemsPerPage = 8;
let buyPropertyData = [];

const BuyInspectionPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pickDate, setPickDate] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchOutlet, setSearchOutlet] = useState(false);

  //redux hooks
  const dispatch = useDispatch();
  const { isLoading, buy_properties } = useSelector((state) => state.buyData);

  useEffect(() => {
    dispatch(getBuyRequest());
  }, [dispatch]);

  // Check rent_properties
  if (Object.keys(buy_properties).length > 0) {
    buyPropertyData = buy_properties.data || [];
  }

  //filter inspection time
  const inspection_properties = buyPropertyData
    .filter((obj) => {
      const hasInspections = obj.inspections.length > 0;
      const isFutureInspection =
        hasInspections &&
        obj.inspections.some((item) =>
          checkCurrentTimeWithTargetTime(item.end_time, item.date)
        );
      return isFutureInspection;
    })
    .sort((a, b) => {
      const dateA = new Date(a.inspections[0].date);
      const dateB = new Date(b.inspections[0].date);
      return dateA - dateB;
    });

  //date change
  const handleDateChange = (event) => {
    setPickDate(event.target.value);
    setSelectedDate(new Date(event.target.value));
  };

  //reset button
  const handleReset = () => {
    setPickDate("");
    setSelectedDate(new Date());
  };

  //search field filter
  const handleSearchInput = (searchCriteria) => {
    const filteredProperties = searchProperty(
      inspection_properties,
      searchCriteria
    );
    setSearchData(filteredProperties);
    setSearchOutlet(true);
  };

  return (
    <>
      <BuyInspectionSearchComponent
        totalProduct={inspection_properties.length}
        getInput={handleSearchInput}
      />

      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-10">
        <div className="flex gap-1 items-start uppercase pb-3">
          <BsFilter className="w-5 h-6" />
          <p>Select Inspection date</p>
        </div>
        <div className="flex items-center gap-3">
          <input
            type="date"
            className="px-3 py-2 rounded border border-yellow focus:outline-yellow cursor-pointer"
            placeholder="MM-DD-YYYY"
            value={pickDate}
            onChange={handleDateChange}
          />
          <ButtonIcon
            onClick={handleReset}
            Icon={<GrPowerReset />}
            className="px-6 py-[13px] rounded border border-yellow focus:outline-yellow cursor-pointer"
          />
        </div>
        {/* card display division */}
        {isLoading ? (
          <div className="h-[30vh] w-full flex items-center justify-center">
            <PuffLoader color="#36d7b7" size={100} />
          </div>
        ) : searchOutlet ? (
          <>
            {searchData.length === 0 ? (
              <NoPropertyFound className="mt-3" />
            ) : (
              <>
                <BuyInspectionPagination
                  items={Object.entries(ByDateCategory(searchData))}
                  itemsPerPage={itemsPerPage}
                />
              </>
            )}
          </>
        ) : (
          <div>
            {inspection_properties.length === 0 && (
              <NoPropertyFound
                className={`${pickDate !== "" ? "hidden" : "block mt-3"}`}
              />
            )}
            {pickDate !== "" ? (
              <div>
                <CurrentDateTime currentDate={selectedDate} />
                {searchTimeWithinProperty(inspection_properties, pickDate) ? (
                  <>
                    <BuyInspectionPagination
                      items={Object.entries(
                        ByDateCategory(inspection_properties)
                      )}
                      itemsPerPage={itemsPerPage}
                      pickDate={pickDate}
                    />
                  </>
                ) : (
                  <NoPropertyFound />
                )}
              </div>
            ) : (
              <>
                <BuyInspectionPagination
                  items={Object.entries(ByDateCategory(inspection_properties))}
                  itemsPerPage={itemsPerPage}
                />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BuyInspectionPage;
