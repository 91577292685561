import React, { useEffect, useState } from "react";
import ExpertSearchComponent from "./component/ExpertSearchComponent";
import ExpertCardComponent from "./component/ExpertCardComponent";
import { useDispatch, useSelector } from "react-redux";
import PuffLoader from "react-spinners/PuffLoader";
import NoExpertFound from "../../components/NoExpertFound";
import { getExpertRequest } from "./ReduxStore/actions";
import { searchExpert } from "../../utils/searchExpert";
import Pagination from "../../components/Pagination";
import { useLocation } from "react-router-dom";

const itemsPerPage = 8;

const ExpertsPage = () => {
  const queryLocation = useLocation();
  const queryParams = new URLSearchParams(queryLocation.search);
  const receivedData = queryParams.get("search");

  const [searchExpertData, setSearchExpertData] = useState([]);
  const [searchExpertOutlet, setSearchExpertOutlet] = useState(false);

  //use redux hooks
  const dispatch = useDispatch();
  const { isLoading, experts_profile } = useSelector(
    (state) => state.expertData
  );

  const [expertPeople, setExpertPeople] = useState([]);

  useEffect(() => {
    dispatch(getExpertRequest());
  }, [dispatch]);

  useEffect(() => {
    // Update local state when experts_profile changes
    if (Object.keys(experts_profile).length !== 0) {
      setExpertPeople(experts_profile.data || []);
    }
  }, [experts_profile]);

  //it used when home page search active
  useEffect(() => {
    if (receivedData !== null) {
      const filterExpert = searchExpert(expertPeople, {
        location: receivedData,
      });
      setSearchExpertData(filterExpert);
      setSearchExpertOutlet(true);
    }
  }, [receivedData, expertPeople]);

  const handleSearchExpertInput = (searchInputData) => {
    const filterExpert = searchExpert(expertPeople, searchInputData);
    setSearchExpertData(filterExpert);
    setSearchExpertOutlet(true);
  };

  return (
    <>
      <ExpertSearchComponent getExpertInput={handleSearchExpertInput} />
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-10">
        {isLoading ? (
          <div className="h-[30vh] w-full flex items-center justify-center">
            <PuffLoader color="#36d7b7" size={100} />
          </div>
        ) : (
          <div className="py-5 flex flex-col justify-center sm:flex-row flex-wrap gap-5">
            {searchExpertOutlet ? (
              <>
                {searchExpertData.length === 0 ? (
                  <NoExpertFound />
                ) : (
                  <>
                    <Pagination
                      items={searchExpertData}
                      itemsPerPage={itemsPerPage}
                      componentName={ExpertCardComponent}
                    />
                  </>
                )}
              </>
            ) : expertPeople.length === 0 ? (
              <NoExpertFound />
            ) : (
              <Pagination
                items={expertPeople}
                itemsPerPage={itemsPerPage}
                componentName={ExpertCardComponent}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ExpertsPage;
