import React, { useState } from "react";
import { Button } from "../../components/Button";
import NavBarMobileSubMenu from "./NavBarMobileSubMenu";
import { AiOutlineClose } from "react-icons/ai";
import { useNavBarOpen } from "./ConextAPI/NavBarContext";
import { NavLink } from "react-router-dom";
import { Accordion } from "@material-tailwind/react";
import {
  buy_sub_menu,
  commercial_sub_menu,
  experts_sub_menu,
  rent_sub_menu,
} from "./NavBarSubMenuData";

const NavigationMobile = () => {
  const { setOpen } = useNavBarOpen();
  const [link, setLink] = useState("");

  const handleClick = (value) => {
    setLink(link === value ? "" : value);
  };

  const activeLink = ({ isActive }) => {
    return {
      color: isActive ? "#F7C332" : "black",
    };
  };

  return (
    <>
      <div className="w-screen h-screen bg-gray/95 relative z-0"></div>
      <div className="absolute z-40 top-0 w-screen h-screen flex flex-col p-6">
        <div className="flex justify-end">
          <p
            className="p-3 bg-ash-200 rounded-full"
            onClick={() => setOpen(false)}
          >
            <AiOutlineClose className="text-white-500" />
          </p>
        </div>
        <div className="flex flex-col justify-center items-center gap-6 min-h-[425px] h-full text-black">
          <div>
            <NavLink
              to="/sell"
              className="text-xs py-3 block w-full uppercase"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              SELL
            </NavLink>
            <Accordion open={link === "/buy"}>
              <NavBarMobileSubMenu
                name="buy"
                subMenu={buy_sub_menu}
                routeTo="/buy"
                onClick={handleClick}
              />
            </Accordion>
            <Accordion open={link === "/rent"}>
              <NavBarMobileSubMenu
                name="rent"
                subMenu={rent_sub_menu}
                routeTo="/rent"
                onClick={handleClick}
              />
            </Accordion>
            <Accordion open={link === "/commercial"}>
              <NavBarMobileSubMenu
                name="commercial"
                subMenu={commercial_sub_menu}
                routeTo="/commercial"
                onClick={handleClick}
              />
            </Accordion>
            <Accordion open={link === "/experts"}>
              <NavBarMobileSubMenu
                name="experts"
                subMenu={experts_sub_menu}
                routeTo="/experts"
                onClick={handleClick}
              />
            </Accordion>
            {/* <NavLink
              to="/strata"
              className="text-xs py-3 block w-full uppercase"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              STRATA
            </NavLink> */}
            <NavLink
              to="/services"
              className="text-xs py-3 block w-full uppercase"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              SERVICES
            </NavLink>
            <NavLink
              to="/contact-us"
              className="text-xs py-3 block w-full uppercase"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              CONTACT
            </NavLink>
          </div>
          <div className="mr-14">
            <a
              href="https://dashboard.primusagents.com.au/"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className="px-4 py-1.5 mr-3 text-xs bg-ash-200 text-white-500"
                name="AGENT LOGIN"
              />
            </a>
            {/* <NavLink to="client-login" onClick={() => setOpen(false)}>
              <Button
                className="px-4 py-1.5 text-xs bg-ash-200 text-white-500"
                name="PROPERTY HUB"
              />
            </NavLink> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationMobile;
