export const getInspectionDateCategory = (item) => {
  const itemByDateCategory = {};

  item.forEach((date) => {
    const index = date.inspection_date; //only change here
    if (!itemByDateCategory[index]) {
      itemByDateCategory[index] = [];
    }
    itemByDateCategory[index].push(date);
  });

  return Object.values(itemByDateCategory).map(
    (itemWithSameDate) => itemWithSameDate
  );
};

export const getAuctionDateCategory = (auction_properties) => {
  const auctionsByDateCategory = {};
  const newDate = new Date();

  auction_properties.forEach((property) => {
    property.auctions.forEach((auction) => {
      const index = auction.date;
      const targetDate = new Date(`${auction.date}T${auction.time}`);

      if (newDate <= targetDate) {
        if (!auctionsByDateCategory[index]) {
          auctionsByDateCategory[index] = [];
        }
        auctionsByDateCategory[index].push(property);
      }
    });
  });

  return auctionsByDateCategory;
};

export const ByDateCategory = (inspection_properties) => {
  const inspectionsByDateCategory = {};
  const newDate = new Date();

  inspection_properties.forEach((property) => {
    property.inspections.forEach((inspection) => {
      const index = inspection.date;
      const targetDate = new Date(`${inspection.date}T${inspection.end_time}`);

      if (newDate <= targetDate) {
        if (!inspectionsByDateCategory[index]) {
          inspectionsByDateCategory[index] = [];
        }
        inspectionsByDateCategory[index].push(property);
      }
    });
  });

  return inspectionsByDateCategory;
};
